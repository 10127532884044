import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PerksModuleStyles } from "./PerksModuleStyles"
import { MdOutlineClose as Cross } from "react-icons/md"
import Perk from "./Perk"

const PerksModule = () => {
  return (
    <PerksModuleStyles className="section section__padding">
      <StaticImage
        className="perks__image--bg"
        src="../../../static/drage02.jpg"
        alt="Perks Module"
        layout="constrained"
        placeholder="tracedSVG"
      />
      <div className="perks__image--overlay"></div>
      <div className="container container__tight">
        <Perk
          title="Drage"
          content="Drage su malo turističko mjesto pokraj Pakoštana. Drage odlikuje razvedena obala s čistim morem i brojnim uvalama i plažama. Tu se nalazi i uređena lučica sa šetalištem duž kojeg se nalaze ugostiteljski objekti.  Osvijetljena riva ugodna je za lagane šetnje nakon večere, za one kojima ne nedostaje gužva i buka disko-klubova."
        >
        </Perk>
      </div>
    </PerksModuleStyles>
  )
}

export default PerksModule
